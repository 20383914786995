import { faCalculatorAlt, faBookReader, faStar } from '@fortawesome/pro-solid-svg-icons';
import { getLanguageData } from './Utils/localeUtilities';

const languageJson = getLanguageData();

export const WebStats = {
  ACCOUNT_ID: process.env.REACT_APP_ACCOUNT_ID,
  HOSTNAME_OVERRIDE: process.env.REACT_APP_HOSTNAME_OVERRIDE,
  HOST: process.env.REACT_APP_OVERRIDE_DOMAIN,
};

export const OverrideDomains = {
  PROD: {
    US: 'school.eb.com',
    GB: 'school.eb.com.uk',
    AU: 'school.eb.com.au',
  },
  QA: {
    US: 'school.qa.eb.com',
    GB: 'school.qa.eb.com.uk',
    AU: 'school.qa.eb.com.au',
  },
  DEV: {
    US: 'school.dev.eb.com',
    GB: 'school.dev.eb.com.uk',
    AU: 'school.dev.eb.com.au',
  },
};

export const HostnameOverrides = {
  PROD: 'webstats.eb.com',
  QA: 'webstats.qa.eb.com',
  DEV: 'webstats.dev.eb.com',
};

export const RoutePath = {
  GAMES: languageJson['routes-games'],
  HOME: languageJson['routes-home'],
  MERCATOR: languageJson['routes-mercator'],
  PABLO: languageJson['routes-pablo'],
  READ: languageJson['routes-read'],
  BROWSE_PLAY: `${languageJson['routes-games']}/${languageJson['routes-browse']}`,
  BROWSE_READ: `${languageJson['routes-read']}/${languageJson['routes-browse']}`,
};

export const RouteHelpers = {
  BROWSE: languageJson['routes-browse'],
  PLAY: languageJson['routes-special-games'],
  READ: languageJson['routes-special-read'],
};

export const Endpoint = {
  BACKEND_API: process.env.REACT_APP_BACKEND_API,
  CDN_URL: process.env.REACT_APP_CDN_URL,
  CDN_MITR_URL: process.env.REACT_APP_MITR_CDN_URL,
};

export const Env = {
  ENVIRONMENT: process.env.REACT_APP_ENV,
  VERSION: process.env.REACT_APP_VERSION,
  RELEASE: process.env.REACT_APP_RELEASE,
};

export const Environments = {
  DEV: 'dev',
  QA: 'qa',
  PRODUCTION: 'prod',
};

export const Locales = {
  PORTUGUESE_BRAZIL: 'pt-BR',
  ENGLISH_US: 'en-US',
  ENGLISH_BRITISH: 'en-GB',
  ENGLISH_AUSTRALIAN: 'en-AU',
};

export const Backgrounds = {
  HOME: { CLASS: 'home-background' },
  CREATE: { CLASS: 'create-background' },
  READ: { CLASS: 'read-background' },
  READ_SOLO: { CLASS: 'read-background-solo' },
  READ_BROWSE: { CLASS: 'read-background-browse' },
  EXPLORE: { CLASS: 'explore-background' },
  PLAY: { CLASS: 'play-background' },
  PLAY_SOLO: { CLASS: 'play-background-solo' },
  PLAY_BROWSE: { CLASS: 'play-background-browse' },
  VAMOSFAZERARTE: { CLASS: 'create-background' },
  VAMOSLER: { CLASS: 'read-background' },
  VAMOSLER_SEÇÃO: { CLASS: 'read-background-browse' },
  VAMOSEXPLORAR: { CLASS: 'explore-background' },
  VAMOSJOGAR: { CLASS: 'play-background' },
  VAMOSJOGAR_SEÇÃO: { CLASS: 'play-background-browse' },
  ERROR: { CLASS: 'error-background' },
};

export const HomePageNav = [
  {
    animationStart: 'bounceIn',
    linkHref: `${languageJson['routes-read']}/${languageJson['routes-browse']}`,
    imgSrc: languageJson['shell-name-image-read'],
    imgAltText: languageJson['shell-name-alt-read'],
    label: languageJson['shell-name-read'],
    btnLabel: languageJson['shell-name-read'],
  },
  {
    animationStart: 'bounceIn',
    linkHref: languageJson['routes-mercator'],
    imgSrc: languageJson['shell-name-image-explore'],
    imgAltText: languageJson['shell-name-alt-explore'],
    label: languageJson['shell-name-explore'],
    btnLabel: languageJson['shell-name-explore'],
  },
  {
    animationStart: 'bounceIn',
    linkHref: `${languageJson['routes-games']}/${languageJson['routes-browse']}`,
    imgSrc: languageJson['shell-name-image-play'],
    imgAltText: languageJson['shell-name-alt-play'],
    label: languageJson['shell-name-play'],
    btnLabel: 'Please ensure that the audio button is turned off. ' + languageJson['shell-name-play'],
  },
  {
    animationStart: 'bounceIn',
    linkHref: languageJson['routes-pablo'],
    imgSrc: languageJson['shell-name-image-create'],
    imgAltText: languageJson['shell-name-alt-create'],
    label: languageJson['shell-name-create'],
    btnLabel: languageJson['shell-name-create'],
  },
];

export const Grades = [
  {
    gradesId: 0,
    name: 'Kindergarten',
  },
  {
    gradesId: 1,
    name: 'First Grade',
  },
  {
    gradesId: 2,
    name: 'Second Grade',
  },
  {
    gradesId: 3,
    name: 'Pre-K',
  },
];

export const GradesRange = [
  {
    gradesRangeId: 0,
    gradesId: [0, 1, 2, 3],
    name: 'Pre-K - 2',
    market: 0,
  },
  {
    gradesRangeId: 1,
    gradesId: [0, 1, 2, 3],
    name: 'Pre-K - 2',
    market: 1,
  },
];

// Category Ids must be unique!
export const Categories = [
  {
    categoriesId: 0,
    categoriesName: 'Math',
    marketsId: 0,
    bucketId: 0,
    icon: faCalculatorAlt,
  },
  {
    categoriesId: 1,
    categoriesName: 'Language Arts',
    marketsId: 0,
    bucketId: 0,
    icon: faBookReader,
  },
  {
    categoriesId: 2,
    categoriesName: 'Brazillian Math',
    marketsId: 1,
    bucketId: 0,
    icon: faCalculatorAlt,
  },
  {
    categoriesId: 3,
    categoriesName: 'Read',
    marketsId: 0,
    bucketId: 1,
    icon: faStar,
  },
  // {
  //   categoriesId: 4,
  //   categoriesName: 'Science',
  //   marketsId: 0,
  //   bucketId: 0,
  //   icon: faFlask,
  // },
  {
    categoriesId: 5,
    categoriesName: 'Brazillian Read',
    marketsId: 1,
    bucketId: 1,
    icon: faStar,
  },
  {
    categoriesId: 6,
    categoriesName: 'Brazillian Language Arts',
    marketsId: 1,
    bucketId: 0,
    icon: faBookReader,
  },
];

export const Markets = [
  {
    marketsId: 0,
    marketsCountry: 'US',
    marketsFullName: 'United States',
    marketsLanguage: 'en-US',
  },
  {
    marketsId: 1,
    marketsCountry: 'BR',
    marketsFullName: 'Brazil',
    marketsLanguage: 'pt-BR',
  },
];

export const Buckets = [
  {
    bucketId: 0,
    bucketName: 'Play',
  },
  {
    bucketId: 1,
    bucketName: 'Read',
  },
];
