import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import './Games.scss';
import {
  getCategoriesByMarketLanguageAndBucketId,
  getCategoriesIdByCategories,
  getAssembliesByCategoryIdAndBucketsId,
} from '../../Utils/browseUtilities';
import BrowseNavComponent from '../../Components/BrowseNavComponent/BrowseNavComponent';
import BrowseListComponent from '../../Components/BrowseListComponent/BrowseListComponent';
import LiveReader from '../../Components/LiveReader/LiveReader';
import { updateLiveReaderInfo } from '../../Utils/audioUtilities';

class GamesPageBrowseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: null,
      games: null,
      activeCategory: null,
    };

    this.handleBrowseNavTabClick = this.handleBrowseNavTabClick.bind(this);
    this.introduction = null;
  }

  componentDidMount() {
    const { startLanguage, gameBrowseScreenReaderGreeting } = this.props;
    const categories = getCategoriesByMarketLanguageAndBucketId(startLanguage, 0);
    const categoryIds = getCategoriesIdByCategories(categories);
    const games = getAssembliesByCategoryIdAndBucketsId(categoryIds[0], 0);

    this.introduction = setTimeout(() => {
      clearTimeout(this.introduction);
      updateLiveReaderInfo(gameBrowseScreenReaderGreeting, 'interactive-text');
    }, 450);
    this.setState({ categories, activeCategory: categoryIds[0], games });
  }

  handleBrowseNavTabClick(e) {
    const categoryId = e.currentTarget.getAttribute('data-category');
    const games = getAssembliesByCategoryIdAndBucketsId(+categoryId, 0);

    document.querySelector(`button[data-category="${categoryId}"]`).focus();

    this.setState({
      activeCategory: +categoryId,
      games,
    });
  }

  render() {
    const { categories, activeCategory, games } = this.state;
    const { pageTitleGames } = this.props;

    return (
      // TODO: Add Section
      <Fragment>
        <Helmet>
          <title>{pageTitleGames}</title>
        </Helmet>
        {categories && (
          <BrowseNavComponent
            categories={categories}
            activeCategory={activeCategory}
            handleBrowseNavTabClick={this.handleBrowseNavTabClick}
          />
        )}

        {games && <BrowseListComponent assemblies={games} bucketId="0" activeCategory={activeCategory} />}
        <LiveReader classHolder="game-summary" />
      </Fragment>
    );
  }
}

GamesPageBrowseContainer.propTypes = {
  startLanguage: PropTypes.string.isRequired,
  pageTitleGames: PropTypes.string.isRequired,
  gameBrowseScreenReaderGreeting: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  startLanguage: state.languageWidget.startLanguage,
  pageTitleGames: state.languageWidget.translation['shell-page-title-play'],
  gameBrowseScreenReaderGreeting: state.languageWidget.translation['play-browse-reader-greeting'],
});

export default connect(mapStateToProps)(GamesPageBrowseContainer);
