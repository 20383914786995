import { getLanguageData } from '../../Utils/localeUtilities';

const languageJson = getLanguageData();

const PlayManifest = [
  {
    id: '0001',
    title: languageJson['0001-play-title'],
    route: '0001',
    topic: languageJson['0001-play-topic'],
    gradeRangeId: [0],
    categoryIds: [0],
    imagePath: languageJson['0001-play-image-path'],
    imagePathAltText: languageJson['0001-play-text-image-alt'],
  },
  {
    id: '0002',
    title: languageJson['0002-play-title'],
    route: '0002',
    topic: languageJson['0002-play-topic'],
    gradeRangeId: [0],
    categoryIds: [0],
    imagePath: languageJson['0002-play-image-path'],
    imagePathAltText: languageJson['0002-play-text-image-alt'],
  },
  {
    id: '0003',
    title: languageJson['0003-play-title'],
    route: '0003',
    topic: languageJson['0003-play-topic'],
    gradeRangeId: [0],
    categoryIds: [0],
    imagePath: languageJson['0003-play-image-path'],
    imagePathAltText: languageJson['0003-play-text-image-alt'],
  },
  {
    id: '0004',
    title: languageJson['0004-play-title'],
    route: '0004',
    topic: languageJson['0004-play-topic'],
    gradeRangeId: [0],
    categoryIds: [0],
    imagePath: languageJson['0004-play-image-path'],
    imagePathAltText: languageJson['0004-play-text-image-alt'],
  },
  {
    id: '0005',
    title: languageJson['0005-play-title'],
    route: '0005',
    topic: languageJson['0005-play-topic'],
    gradeRangeId: [0],
    categoryIds: [0],
    imagePath: languageJson['0005-play-image-path'],
    imagePathAltText: languageJson['0005-play-text-image-alt'],
  },
  {
    id: '0006',
    title: languageJson['0006-play-title'],
    route: '0006',
    topic: languageJson['0006-play-topic'],
    gradeRangeId: [0],
    categoryIds: [1],
    imagePath: languageJson['0006-play-image-path'],
    imagePathAltText: languageJson['0006-play-text-image-alt'],
  },
  {
    id: '0007',
    title: languageJson['0007-play-title'],
    route: '0007',
    topic: languageJson['0007-play-topic'],
    gradeRangeId: [0],
    categoryIds: [1],
    imagePath: languageJson['0007-play-image-path'],
    imagePathAltText: languageJson['0007-play-text-image-alt'],
  },
  {
    id: '0008',
    title: languageJson['0008-play-title'],
    route: '0008',
    topic: languageJson['0008-play-topic'],
    gradeRangeId: [0],
    categoryIds: [1],
    imagePath: languageJson['0008-play-image-path'],
    imagePathAltText: languageJson['0008-play-text-image-alt'],
  },
  {
    id: '0009',
    title: languageJson['0009-play-title'],
    route: '0009',
    topic: languageJson['0009-play-topic'],
    gradeRangeId: [0],
    categoryIds: [1],
    imagePath: languageJson['0009-play-image-path'],
    imagePathAltText: languageJson['0009-play-text-image-alt'],
  },
  {
    id: '0010',
    title: languageJson['0010-play-title'],
    route: '0010',
    topic: languageJson['0010-play-topic'],
    gradeRangeId: [0],
    categoryIds: [1],
    imagePath: languageJson['0010-play-image-path'],
    imagePathAltText: languageJson['0010-play-text-image-alt'],
  },
  // {
  //   id: '0009',
  //   title: 'Science',
  //   route: '0009',
  //   topic: 'Science 0009',
  //   gradeRangeId: [1],
  //   categoryIds: [2],
  //   imagePath: languageJson['0002-play-image-path'],
  //   imagePathAltText: languageJson['0002-play-image-path'],
  // },
  {
    id: '0011',
    title: languageJson['0011-play-title'],
    route: '0011',
    topic: languageJson['0011-play-topic'],
    gradeRangeId: [1],
    categoryIds: [2],
    imagePath: languageJson['0011-play-image-path'],
    imagePathAltText: languageJson['0011-play-text-image-alt'],
  },
  {
    id: '0012',
    title: languageJson['0012-play-title'],
    route: '0012',
    topic: languageJson['0012-play-topic'],
    gradeRangeId: [1],
    categoryIds: [2],
    imagePath: languageJson['0012-play-image-path'],
    imagePathAltText: languageJson['0012-play-text-image-alt'],
  },
  {
    id: '0013',
    title: languageJson['0013-play-title'],
    route: '0013',
    topic: languageJson['0013-play-topic'],
    gradeRangeId: [1],
    categoryIds: [2],
    imagePath: languageJson['0013-play-image-path'],
    imagePathAltText: languageJson['0013-play-text-image-alt'],
  },
  {
    id: '0014',
    title: languageJson['0014-play-title'],
    route: '0014',
    topic: languageJson['0014-play-topic'],
    gradeRangeId: [1],
    categoryIds: [2],
    imagePath: languageJson['0014-play-image-path'],
    imagePathAltText: languageJson['0014-play-text-image-alt'],
  },
  {
    id: '0015',
    title: languageJson['0015-play-title'],
    route: '0015',
    topic: languageJson['0015-play-topic'],
    gradeRangeId: [1],
    categoryIds: [2],
    imagePath: languageJson['0015-play-image-path'],
    imagePathAltText: languageJson['0015-play-text-image-alt'],
  },
  {
    id: '0016',
    title: languageJson['0016-play-title'],
    route: '0016',
    topic: languageJson['0016-play-topic'],
    gradeRangeId: [1],
    categoryIds: [6],
    imagePath: languageJson['0016-play-image-path'],
    imagePathAltText: languageJson['0016-play-text-image-alt'],
  },
  {
    id: '0017',
    title: languageJson['0017-play-title'],
    route: '0017',
    topic: languageJson['0017-play-topic'],
    gradeRangeId: [1],
    categoryIds: [6],
    imagePath: languageJson['0017-play-image-path'],
    imagePathAltText: languageJson['0017-play-text-image-alt'],
  },
  {
    id: '0018',
    title: languageJson['0018-play-title'],
    route: '0018',
    topic: languageJson['0018-play-topic'],
    gradeRangeId: [1],
    categoryIds: [6],
    imagePath: languageJson['0018-play-image-path'],
    imagePathAltText: languageJson['0018-play-text-image-alt'],
  },
  {
    id: '0019',
    title: languageJson['0019-play-title'],
    route: '0019',
    topic: languageJson['0019-play-topic'],
    gradeRangeId: [1],
    categoryIds: [6],
    imagePath: languageJson['0019-play-image-path'],
    imagePathAltText: languageJson['0019-play-text-image-alt'],
  },
  {
    id: '0020',
    title: languageJson['0020-play-title'],
    route: '0020',
    topic: languageJson['0020-play-topic'],
    gradeRangeId: [1],
    categoryIds: [6],
    imagePath: languageJson['0020-play-image-path'],
    imagePathAltText: languageJson['0020-play-text-image-alt'],
  },
];

export default PlayManifest;
