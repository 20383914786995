import { TOGGLE_LANGUAGE, SWITCH_LANGUAGE } from '../actionTypes';
import { getLocationCode, getLanguageData } from '../../Utils/localeUtilities';

const startLanguage = getLocationCode();
const initialState = { startLanguage, translation: getLanguageData() };

window.sessionStorage.setItem('_eb_language', startLanguage);

const language = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LANGUAGE: {
      return { ...state, activeCountryCode: getNewLanguage(state.activeCountryCode) };
    }

    case SWITCH_LANGUAGE: {
      return { ...state, activeCountryCode: state.activeCountryCode };
    }

    default: {
      return state;
    }
  }
};

export default language;

function getNewLanguage(code) {
  switch (code) {
    case 'br':
      return 'en';
    case 'en':
      return 'br';
    default:
      return 'en';
  }
}
