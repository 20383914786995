import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccesibleIconButton = props => {
  const {
    name,
    elementId,
    className,
    translationKey,
    icon,
    callback,
    src,
    alt,
    containerClassName,
    dataCategory,
    label,
    nonTranslatedText,
    nonTranslatedLabel,
    title,
    refLink,
  } = props;
  const btnClass = containerClassName || '';

  return (
    <Fragment>
      <button
        key={elementId}
        type="button"
        className={btnClass}
        data-category={dataCategory}
        name={name}
        id={elementId}
        onClick={callback}
        title={title}
        ref={refLink}
      >
        {/* some sort of svg */}
        {icon && className && <FontAwesomeIcon className={className} icon={icon} />}
        {icon && !className && <FontAwesomeIcon icon={icon} />}
        {src && !icon && <img className={className} alt={alt} src={src} />}
        {label && (
          <span className="button-label">
            <Translate id={label} />
          </span>
        )}
        {!label && nonTranslatedText && <span className="button-label">{nonTranslatedText}</span>}
        {translationKey && (
          <span className="sr-only">
            <Translate id={translationKey} />
          </span>
        )}
        {!translationKey && nonTranslatedLabel && <span className="sr-only">{nonTranslatedLabel}</span>}
      </button>
    </Fragment>
  );
};

AccesibleIconButton.propTypes = {
  name: PropTypes.string,
  elementId: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  translationKey: PropTypes.string,
  icon: PropTypes.shape(),
  callback: PropTypes.func,
  src: PropTypes.string,
  alt: PropTypes.string,
  label: PropTypes.string,
  dataCategory: PropTypes.number,
  nonTranslatedLabel: PropTypes.string,
  nonTranslatedText: PropTypes.string,
  title: PropTypes.string,
  refLink: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

AccesibleIconButton.defaultProps = {
  name: 'button',
  className: null,
  containerClassName: null,
  elementId: null,
  icon: null,
  callback: null,
  src: null,
  alt: null,
  label: null,
  dataCategory: null,
  nonTranslatedLabel: null,
  nonTranslatedText: null,
  translationKey: null,
  title: '',
  refLink: null,
};

export default AccesibleIconButton;
