/**
 * @param   {[String]} cookies [List of document cookies]
 * @param   {[String]} name    [Name of cookie whose value should be returned]
 * @returns {[String]}         [Value of cookie]
 */

export function getCookieByName(cookies, name) {
  // Add semicolon to the beginning of cookie string to create consistency among the values
  const value = `; ${cookies}`;
  // Split the cookie string into an array on following delimiter
  // This will create an array where the value of the given cookie name is the first string in the second element
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    // If parts has two elements pop off the second element, create a new array from this string
    // and grab the first element from the new array which is the value of cookie specified
    return parts
      .pop()
      .split(';')
      .shift();
  }

  return null;
}

export default {
  getCookieByName,
};
