import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RoutePath, Endpoint } from '../../constants';
import './BackToHome.scss';

const BackToHome = props => {
  const { backToHomeAlt, brandingHeaderAlt, backToHomePath, brandingHeaderPath, backToHomeSr } = props;

  return (
    <Link to={RoutePath.HOME} tabIndex="0">
      <div className="BackToHome" aria-label={backToHomeSr}>
        <img
          title={backToHomeAlt}
          className="home-btn"
          alt={backToHomeAlt}
          src={`${Endpoint.CDN_URL}${backToHomePath}`}
          aria-hidden="true"
        />
        <div className="branding-btn" title={backToHomeAlt} aria-hidden="true">
          <img src={`${Endpoint.CDN_URL}${brandingHeaderPath}`} alt={brandingHeaderAlt} className="branding" />
        </div>
      </div>
    </Link>
  );
};

BackToHome.propTypes = {
  backToHomeAlt: PropTypes.string.isRequired,
  brandingHeaderAlt: PropTypes.string.isRequired,
  backToHomePath: PropTypes.string.isRequired,
  brandingHeaderPath: PropTypes.string.isRequired,
  backToHomeSr: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  backToHomeAlt: state.languageWidget.translation['shell-alt-back-to-home'],
  brandingHeaderAlt: state.languageWidget.translation['shell-alt-branding-header'],
  brandingHeaderPath: state.languageWidget.translation['shell-name-image-header-branding'],
  backToHomePath: state.languageWidget.translation['shell-name-image-home'],
  backToHomeSr: state.languageWidget.translation['shell-sr-back-to-home'],
});

export default connect(mapStateToProps)(BackToHome);
