import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RoutePath, RouteHelpers } from '../../constants';
import GamesPageBrowseContainer from './GamesPageBrowseContainer';
import GameComponent from './GamePageComponents/GameComponent';
import { getLocalesByBucketIdAndRouteId } from '../../Utils/browseUtilities';

const GamesPageRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path={`${RoutePath.GAMES}/0001`} gamePath="math-2" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0002`} gamePath="math-5" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0003`} gamePath="math-1" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0004`} gamePath="math-3" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0005`} gamePath="math-4" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0006`} gamePath="ela-1" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0007`} gamePath="ela-2" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0008`} gamePath="ela-3" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0009`} gamePath="ela-4" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0010`} gamePath="ela-5" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0011`} gamePath="math-2" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0012`} gamePath="math-5" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0013`} gamePath="math-1" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0014`} gamePath="math-3" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0015`} gamePath="math-4" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0016`} gamePath="ela-1" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0017`} gamePath="ela-2" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0018`} gamePath="ela-3" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0019`} gamePath="ela-4" />
      <PrivateRoute exact path={`${RoutePath.GAMES}/0020`} gamePath="ela-5" />

      <Route exact path={`${RoutePath.GAMES}/${RouteHelpers.BROWSE}`} component={GamesPageBrowseContainer} />
      <Route render={() => <Redirect to={`${RoutePath.GAMES}/${RouteHelpers.BROWSE}`} />} />
    </Switch>
  );
};

export default GamesPageRoutes;

function PrivateRoute(params) {
  return (
    <Route
      render={props =>
        authenticate(window.sessionStorage.getItem('_eb_language'), params.path) !== -1 ? (
          <GameComponent {...props} gamePath={params.gamePath} />
        ) : (
          <Redirect to={`${RoutePath.GAMES}/${RouteHelpers.BROWSE}`} />
        )
      }
    />
  );
}

function authenticate(language, path) {
  return getLocalesByBucketIdAndRouteId(0, path.split('/').pop()).indexOf(language);
}
