import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Endpoint } from '../../constants';
import HomeTemplate from '../Templates/Home/HomeTemplate';
import './Home.scss';

const HomePage = props => {
  const { helloImageAlt, helloImagePath } = props;

  return (
    <HomeTemplate>
      <div className="HomePage">
        <Row className="title-container">
          <Col className="col-sm-4 col-lg-4">
            <img
              src={`${Endpoint.CDN_URL}${helloImagePath}`}
              alt={helloImageAlt}
              className="hello-image"
              aria-hidden="true"
            />
            <span className="sr-only" role="heading" aria-level="1">
              <Translate id="shell-sr-hello" />
            </span>
          </Col>
          <Col className="message-container col-sm-8 col-lg-8">
            <h2 className="title-message">
              <Translate id="shell-title-text" />
            </h2>
          </Col>
        </Row>
      </div>
    </HomeTemplate>
  );
};

HomePage.propTypes = {
  helloImageAlt: PropTypes.string.isRequired,
  helloImagePath: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  helloImageAlt: state.languageWidget.translation['shell-alt-hello'],
  helloImagePath: state.languageWidget.translation['shell-name-image-hello'],
});

export default connect(mapStateToProps)(HomePage);
