import {
  faPaintBrush,
  faPencil,
  faSprayCan,
  faEraser,
  faFillDrip,
  faImages,
  faPrint,
  faRedoAlt,
  faUndoAlt,
  faTrashAlt,
  faStamp,
} from '@fortawesome/pro-solid-svg-icons';
import store from '../../Redux/store';

const { host, protocol } = window.location;
const voiceOverFiles = JSON.parse(store.getState().languageWidget.translation['pablo-sounds-voice-overs']);
const stickerImagesLocalPath = '/images/Pablo/stickers/';
const backgroundImagesLocalPath = '/images/Pablo/backgrounds/';
const backgroundImagesPath = `${protocol}//${host}${backgroundImagesLocalPath}`;
const stickerImagesPath = `${protocol}//${host}${stickerImagesLocalPath}`;

export const PabloConstants = {
  ENABLED_COLORS: [
    { hex: '#ED083F', name: 'red' },
    { hex: '#FF8833', name: 'orange' },
    { hex: '#FAED3E', name: 'yellow' },
    { hex: '#008C00', name: 'green' },
    { hex: '#0C6CF2', name: 'blue' },
    { hex: '#9C2DB3', name: 'purple' },
    { hex: '#8C5115', name: 'brown' },
    { hex: '#000000', name: 'black' },
    { hex: '#C0448F', name: 'red-violet' },
    { hex: '#FF5349', name: 'red-orange' },
    { hex: '#C5E384', name: 'yellow-green' },
    { hex: '#7366BD', name: 'blue-violet' },
    { hex: '#FFAACC', name: 'carnation-pink' },
    { hex: '#FFB653', name: 'yellow-orange' },
    { hex: '#199EBD', name: 'blue-green' },
    { hex: '#FFFFFF', name: 'white' },
  ],
  DEFAULT_SELECTED_COLOR: '#000000',
  DEFAULT_SELECTED_STICKER: {
    PATH: `${stickerImagesPath}bird.svg`,
    NAME: 'bird.svg',
  },
  DEFAULT_SELECTED_BACKGROUND: {
    NAME: 'delete.svg',
    PATH: `${backgroundImagesPath}default.svg`,
  },
  BRUSHES: ['eraser', 'fill', 'marker', 'pencil', 'spray', 'pattern', 'circle'],
  SPRAY_BRUSH_ALTERNATIVE_STYLE: 'circle',
  TOOLBAR_TOOLS: [
    { toolName: 'marker', brush: true, toolIcon: faPaintBrush, toolbarLocation: 'right' },
    { toolName: 'pencil', brush: true, toolIcon: faPencil, toolbarLocation: 'right' },
    { toolName: 'spray', brush: true, toolIcon: faSprayCan, toolbarLocation: 'right' },
    { toolName: 'fill', brush: true, toolIcon: faFillDrip, toolbarLocation: 'right' },
    // Change brush value to false and toolName to 'sticker' to revert back to original sticker logic
    { toolName: 'pattern', brush: true, toolIcon: faStamp, toolbarLocation: 'right' },
    { toolName: 'eraser', brush: true, toolIcon: faEraser, toolbarLocation: 'right' },
    { toolName: 'undo', brush: false, toolIcon: faUndoAlt, toolbarLocation: 'left' },
    { toolName: 'redo', brush: false, toolIcon: faRedoAlt, toolbarLocation: 'left' },
    { toolName: 'clear', brush: false, toolIcon: faTrashAlt, toolbarLocation: 'left' },
    { toolName: 'print', brush: false, toolIcon: faPrint, toolbarLocation: 'left' },
    { toolName: 'set-background', brush: false, toolIcon: faImages, toolbarLocation: 'left' },
  ],
  TOOL: {
    MARKER: 'marker',
    PENCIL: 'pencil',
    SPRAY: 'spray',
    // Change STICKER value to 'sticker' to revert back to original sticker logic
    STICKER: 'pattern',
    ERASER: 'eraser',
    FILL: 'fill',
    SET_BACKGROUND: 'set-background',
    PRINT: 'print',
    UNDO: 'undo',
    REDO: 'redo',
    CLEAR: 'clear',
  },
  STICKER_IMAGE_NAMES: [
    'bird.svg',
    'butterfly.svg',
    'car.svg',
    'clouds.svg',
    'flower.svg',
    'hot-air-balloon.svg',
    'moon-and-stars.svg',
    'star.svg',
    'sun.svg',
    'tree.svg',
  ],
  STICKER_IMAGE_PATHS: [
    `${stickerImagesPath}bird.svg`,
    `${stickerImagesPath}butterfly.svg`,
    `${stickerImagesPath}car.svg`,
    `${stickerImagesPath}clouds.svg`,
    `${stickerImagesPath}flower.svg`,
    `${stickerImagesPath}hot-air-balloon.svg`,
    `${stickerImagesPath}moon-and-stars.svg`,
    `${stickerImagesPath}star.svg`,
    `${stickerImagesPath}sun.svg`,
    `${stickerImagesPath}tree.svg`,
  ],
  BACKGROUND_IMAGE_PATHS: [
    `${backgroundImagesPath}beach.svg`,
    `${backgroundImagesPath}delete.svg`,
    `${backgroundImagesPath}library.svg`,
    `${backgroundImagesPath}moonscape.svg`,
    `${backgroundImagesPath}nature.svg`,
    `${backgroundImagesPath}room.svg`,
    `${backgroundImagesPath}underwater.svg`,
    `${backgroundImagesPath}sky.svg`,
    `${backgroundImagesPath}soccer-stadium.svg`,
    `${backgroundImagesPath}space.svg`,
    `${backgroundImagesPath}default.svg`,
  ],
  BACKGROUND_IMAGE_NAMES: [
    'beach.svg',
    'library.svg',
    'moonscape.svg',
    'nature.svg',
    'room.svg',
    'underwater.svg',
    'sky.svg',
    'soccer-stadium.svg',
    'space.svg',
    'delete.svg',
  ],
  STICKERS_PATH_PREFIX: `${stickerImagesLocalPath}`,
  BACKGROUNDS_PATH_PREFIX: `${backgroundImagesLocalPath}`,
  CURSORS_PATH_PREFIX: '/images/Pablo/cursors/',
  BRUSH_WIDTHS: {
    PENCIL: 5,
    MARKER: 25,
    PATTERN: 128,
    SPRAY: 5,
    ERASER: 50,
  },
  DRAWER_RIGHT_OUT_ANIMATION: 'fadeOutRight',
  DRAWER_RIGHT_IN_ANIMATION: 'fadeInRight',
  DRAWER_LEFT_IN_ANIMATION: 'fadeInLeft',
  DRAWER_LEFT_OUT_ANIMATION: 'fadeOutLeft',
  ARROW_HEIGHT: 50,
  WIDTHS: [1, 5, 10, 25, 50, 128],
  SOUNDS: {
    VOICEOVER_FILES: voiceOverFiles,
  },
  SOUND_PATH_PREFIXES: {
    EN_US: '/audio/Pablo/en-US/',
    PT_BR: '/audio/Pablo/pt-BR/',
  },
  SOUND_REPLACE_FILE_FORMATS: {
    EN_US: '_en-US.mp3',
    PT_BR: '_pt-BR.mp3',
  },
  CURSOR_OFFSETS: {
    PENCIL: 2.5,
    MARKER: 12.5,
    ERASER: 25,
    DEFAULT: 16,
    SPRAY: 5,
    PATTERN_HORIZONTAL: 25,
    PATTERN_VERTICAL: 49,
  },
};

export default PabloConstants;
