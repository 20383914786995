import { getLanguageData } from '../../Utils/localeUtilities';

const languageJson = getLanguageData();

const ReadManifest = [
  /*--*/
  {
    id: '0001',
    title: languageJson['0001-read-title'],
    route: '0001',
    topic: languageJson['0001-read-topic'],
    gradeRangeId: [0],
    categoryIds: [3],
    imagePath: languageJson['0001-read-image-path'],
    imagePathAltText: languageJson['0001-read-text-image-alt'],
  },
  /*--*/
  {
    id: '0002',
    title: languageJson['0002-read-title'],
    route: '0002',
    topic: languageJson['0002-read-topic'],
    gradeRangeId: [0],
    categoryIds: [3],
    imagePath: languageJson['0002-read-image-path'],
    imagePathAltText: languageJson['0002-read-text-image-alt'],
  },
  {
    id: '0006',
    title: languageJson['0006-read-title'],
    route: '0006',
    topic: languageJson['0006-read-topic'],
    gradeRangeId: [0],
    categoryIds: [3],
    imagePath: languageJson['0006-read-image-path'],
    imagePathAltText: languageJson['0006-read-text-image-alt'],
  },
  /*--*/
  {
    id: '0013',
    title: languageJson['0013-read-title'],
    route: '0013',
    topic: languageJson['0013-read-topic'],
    gradeRangeId: [0],
    categoryIds: [3],
    imagePath: languageJson['0013-read-image-path'],
    imagePathAltText: languageJson['0013-read-text-image-alt'],
  },

  {
    id: '0012',
    title: languageJson['0012-read-title'],
    route: '0012',
    topic: languageJson['0012-read-topic'],
    gradeRangeId: [0],
    categoryIds: [3],
    imagePath: languageJson['0012-read-image-path'],
    imagePathAltText: languageJson['0012-read-text-image-alt'],
  },
  {
    id: '0014',
    title: languageJson['0014-read-title'],
    route: '0014',
    topic: languageJson['0014-read-topic'],
    gradeRangeId: [0],
    categoryIds: [5],
    imagePath: languageJson['0014-read-image-path'],
    imagePathAltText: languageJson['0014-read-text-image-alt'],
  },
  {
    id: '0015',
    title: languageJson['0015-read-title'],
    route: '0015',
    topic: languageJson['0015-read-topic'],
    gradeRangeId: [0],
    categoryIds: [5],
    imagePath: languageJson['0015-read-image-path'],
    imagePathAltText: languageJson['0015-read-text-image-alt'],
  },
  {
    id: '0016',
    title: languageJson['0016-read-title'],
    route: '0016',
    topic: languageJson['0016-read-topic'],
    gradeRangeId: [0],
    categoryIds: [5],
    imagePath: languageJson['0016-read-image-path'],
    imagePathAltText: languageJson['0016-read-text-image-alt'],
  },
  {
    id: '0017',
    title: languageJson['0017-read-title'],
    route: '0017',
    topic: languageJson['0017-read-topic'],
    gradeRangeId: [0],
    categoryIds: [5],
    imagePath: languageJson['0017-read-image-path'],
    imagePathAltText: languageJson['0017-read-text-image-alt'],
  },
  {
    id: '0018',
    title: languageJson['0018-read-title'],
    route: '0018',
    topic: languageJson['0018-read-topic'],
    gradeRangeId: [0],
    categoryIds: [5],
    imagePath: languageJson['0018-read-image-path'],
    imagePathAltText: languageJson['0018-read-text-image-alt'],
  },
  {
    id: '0021',
    title: languageJson['0021-read-title'],
    route: '0021',
    topic: languageJson['0021-read-topic'],
    gradeRangeId: [1],
    categoryIds: [5],
    imagePath: languageJson['0021-read-image-path'],
    imagePathAltText: languageJson['0021-read-text-image-alt'],
  },
  {
    id: '0022',
    title: languageJson['0022-read-title'],
    route: '0022',
    topic: languageJson['0022-read-topic'],
    gradeRangeId: [1],
    categoryIds: [5],
    imagePath: languageJson['0022-read-image-path'],
    imagePathAltText: languageJson['0022-read-text-image-alt'],
  },
  {
    id: '0023',
    title: languageJson['0023-read-title'],
    route: '0023',
    topic: languageJson['0023-read-topic'],
    gradeRangeId: [1],
    categoryIds: [5],
    imagePath: languageJson['0023-read-image-path'],
    imagePathAltText: languageJson['0023-read-text-image-alt'],
  },
  {
    id: '0024',
    title: languageJson['0024-read-title'],
    route: '0024',
    topic: languageJson['0024-read-topic'],
    gradeRangeId: [1],
    categoryIds: [5],
    imagePath: languageJson['0024-read-image-path'],
    imagePathAltText: languageJson['0024-read-text-image-alt'],
  },
  {
    id: '0025',
    title: languageJson['0025-read-title'],
    route: '0025',
    topic: languageJson['0025-read-topic'],
    gradeRangeId: [1],
    categoryIds: [5],
    imagePath: languageJson['0025-read-image-path'],
    imagePathAltText: languageJson['0025-read-text-image-alt'],
  },
];

export default ReadManifest;
