import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RoutePath } from './constants';
import MercatorPageContainer from './Pages/Mercator/MercatorPageContainer';
import PabloPageContainer from './Pages/Pablo/PabloPageContainer';
import HomePageContainer from './Pages/Home/HomePageContainer';
import BooksPageContainer from './Pages/Books/BooksPageContainer';
import GenericNotFound from './Pages/404/GenericNotFound';
import GamesPageContainer from './Pages/Games/GamesPageContainer';

const AppRoutes = () => (
  <Switch>
    <Route exact path={RoutePath.PABLO} component={PabloPageContainer} />
    <Route exact path={RoutePath.MERCATOR} component={MercatorPageContainer} />
    <Route path={RoutePath.GAMES} component={GamesPageContainer} />
    <Route exact path={RoutePath.HOME} component={HomePageContainer} />
    <Route path={RoutePath.READ} component={BooksPageContainer} />
    <Redirect exact from="/" to={RoutePath.HOME} />
    <Route component={GenericNotFound} />
  </Switch>
);

export default AppRoutes;
