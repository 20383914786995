import React from 'react';
import cx from 'classnames';
// eslint-disable-next-line no-unused-vars
import { TweenLite, Power2, TimelineLite, CSSPlugin } from 'gsap/all';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import toggleAirdropAnimation from '../../Redux/actions/animationActions';
import PrimaryNavigationItem from './PrimaryNavigationItem';
import { HomePageNav, Endpoint } from '../../constants';
import './PrimaryNavigation.scss';

class PrimaryNavigation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.animateAirDropIconsFin = this.animateAirDropIconsFin.bind(this);
    this.primaryNavigationIconAnimation = new TimelineLite({ paused: true });
  }

  componentDidMount() {
    const { airDrop } = this.props;

    if (airDrop) {
      this.primaryNavigationIconAnimation
        .add(
          TweenLite.to('.primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item', 1, {
            marginTop: '25',
            ease: Power2.easeOut,
          })
        )
        .duration(1)
        .delay(0.1)
        .eventCallback('onComplete', this.animateAirDropIconsFin);
      this.primaryNavigationIconAnimation.play();
    } else {
      const activeIconAnimation = new TimelineLite({ paused: true });

      activeIconAnimation.add(
        TweenLite.to('.primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item.active', 1, {
          scale: 0.7,
          ease: Power2.easeOut,
          delay: 0,
        })
      );

      activeIconAnimation.add(
        TweenLite.to('.primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item.active', 1, {
          scale: 1.2,
          ease: Power2.easeOut,
          delay: 0,
        })
      );

      activeIconAnimation.play();
    }
  }

  animateAirDropIconsFin() {
    const { toggleAirdropAnimation } = this.props;

    TweenLite.to('.primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item.active', 1, {
      scale: 1.2,
      ease: Power2.easeOut,
    });

    toggleAirdropAnimation();
  }

  render() {
    const { airDrop } = this.props;
    const navClass = cx('primary-navigation-wrapper', { raised: airDrop });

    return (
      <div className={navClass}>
        <nav className="primary-navigation" role="navigation">
          {HomePageNav.map(navItem => (
            <PrimaryNavigationItem
              linkHref={navItem.linkHref}
              imgSrc={`${Endpoint.CDN_URL}/learning-zone${navItem.imgSrc}`}
              imgAltText={navItem.imgAltText}
              label={navItem.label}
              btnLabel={navItem.btnLabel}
              key={navItem.label}
            />
          ))}
        </nav>
      </div>
    );
  }
}

PrimaryNavigation.propTypes = {
  airDrop: PropTypes.bool.isRequired,
  toggleAirdropAnimation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  airDrop: state.animationManager.navigationAirdropAnimation,
});

export default connect(
  mapStateToProps,
  { toggleAirdropAnimation }
)(PrimaryNavigation);
