import React from 'react';
import ReactJWPlayer from 'react-jw-player';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Endpoint } from '../../constants';
import AccesibleIconButton from '../AccessibleIconButton/AccessibleIconButton';
import './MediaOverlay.scss';
import AnalyticsAPI from '../../Services/AnalyticsApi';

class OverlayVideo extends React.Component {
  constructor(props) {
    super(props);

    const { filename, jwplayerManifestId, jwplayerClosedCaptionManifestId, credit, transcript, caption } = this.props;

    this.state = {
      filename,
      jwplayerManifestId,
      jwplayerClosedCaptionManifestId,
      credit,
      transcript,
      caption,
      isTranscriptOpen: false,
    };

    window.addEventListener('_pause-change', () => {
      const { pause } = this.props;

      if (!pause) {
        window.jwplayer('bmo-video-player').pause();
      } else {
        window.jwplayer('bmo-video-player').play();
      }
    });

    this.toggleTranscript = this.toggleTranscript.bind(this);
  }

  componentDidMount() {
    AnalyticsAPI.manualWriteStat('/fundamentals/assets/video');
  }

  componentWillUnmount() {
    window.removeEventListener('_pause-change', () => {
      console.log('Removing Event Listener');
    });
  }

  toggleTranscript() {
    const { isTranscriptOpen } = this.state;

    this.setState({
      isTranscriptOpen: !isTranscriptOpen,
    });
  }

  render() {
    const {
      filename,
      jwplayerManifestId,
      jwplayerClosedCaptionManifestId,
      credit,
      transcript,
      caption,
      isTranscriptOpen,
      isPaused,
    } = this.state;

    const btnString = isTranscriptOpen ? 'mercator-string-close-transcript' : 'mercator-string-open-transcript';

    const figCaptionClass = isTranscriptOpen ? classNames('hide') : classNames('');

    const transcriptClass = isTranscriptOpen
      ? classNames('media-overlay-transcript', 'transcript')
      : classNames('media-overlay-transcript', 'transcript', 'hide');

    const { audio, startLanguage } = this.props;

    const ccLanguage = startLanguage === 'pt_BR' ? 'Português' : 'English';

    return (
      <div tabIndex="0" className=" mx-auto  media-overlay-container">
        <ReactJWPlayer
          isMuted={!audio}
          isPaused={isPaused}
          playerId="bmo-video-player"
          onReady={() => document.getElementsByClassName('media-overlay-container')[0].focus()}
          playerScript="https://content.jwplatform.com/libraries/p7ZA3N0E.js"
          customProps={{ playbackRateControls: [0.5, 0.75, 1, 1.25, 1.5, 2.0] }}
          playlist={[
            {
              image: `${Endpoint.CDN_URL}${filename}`,
              sources: [
                {
                  default: false,
                  file: `https://content.jwplatform.com/manifests/${jwplayerManifestId}.m3u8`,
                  label: '0',
                  preload: 'metadata',
                  type: 'hls',
                },
              ],
              tracks: [
                {
                  file: jwplayerClosedCaptionManifestId
                    ? `https://content.jwplatform.com/tracks/${jwplayerClosedCaptionManifestId}`
                    : null,
                  label: ccLanguage,
                  kind: 'captions',
                  default: false,
                },
              ],
            },
          ]}
        />

        <figcaption className={figCaptionClass}>
          <p className="caption">{caption}</p>
          <p className="credit">{credit}</p>
        </figcaption>
        <AccesibleIconButton
          callback={this.toggleTranscript}
          containerClassName="button-transcript"
          label={btnString}
        />
        <div className={transcriptClass}>{transcript}</div>
      </div>
    );
  }
}

OverlayVideo.propTypes = {
  audio: PropTypes.bool.isRequired,
  startLanguage: PropTypes.string.isRequired,
  credit: PropTypes.string,
  filename: PropTypes.string,
  jwplayerManifestId: PropTypes.string.isRequired,
  jwplayerClosedCaptionManifestId: PropTypes.string,
  transcript: PropTypes.string,
  caption: PropTypes.string,
  pause: PropTypes.bool,
};

OverlayVideo.defaultProps = {
  credit: null,
  filename: null,
  jwplayerClosedCaptionManifestId: null,
  transcript: null,
  caption: null,
  pause: false,
};

const mapStateToProps = state => ({
  audio: state.audioWidget.audio,
  pause: state.pauseManager.pause,
  startLanguage: state.languageWidget.startLanguage,
});

export default connect(mapStateToProps)(OverlayVideo);
