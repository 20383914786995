import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RoutePath, RouteHelpers } from '../../constants';
import BooksPageBrowseContainer from './BooksPageBrowseContainer';
import BooksComponent from './BooksPageComponents/BooksComponent';
import { getLocalesByBucketIdAndRouteId } from '../../Utils/browseUtilities';

const BooksPageRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path={`${RoutePath.READ}/0001`} gamePath="bdl-animals" />
      <PrivateRoute exact path={`${RoutePath.READ}/0002`} gamePath="bdl-colors" />
      <PrivateRoute exact path={`${RoutePath.READ}/0012`} gamePath="bdl-me_and_you" />
      <PrivateRoute exact path={`${RoutePath.READ}/0006`} gamePath="bdl-people" />
      <PrivateRoute exact path={`${RoutePath.READ}/0013`} gamePath="bdl-shapes" />
      <PrivateRoute exact path={`${RoutePath.READ}/0021`} gamePath="bdl-shapes_portuguese" />
      {/* Need to update gamePath variables below for Portuguese versions of books */}
      <PrivateRoute exact path={`${RoutePath.READ}/0022`} gamePath="bdl-animals" />
      <PrivateRoute exact path={`${RoutePath.READ}/0023`} gamePath="bdl-colors" />
      <PrivateRoute exact path={`${RoutePath.READ}/0024`} gamePath="bdl-me_and_you" />
      <PrivateRoute exact path={`${RoutePath.READ}/0025`} gamePath="bdl-people" />

      <Route exact path={`${RoutePath.READ}/${RouteHelpers.BROWSE}`} component={BooksPageBrowseContainer} />
      <Route render={() => <Redirect to={`${RoutePath.READ}/${RouteHelpers.BROWSE}`} />} />
    </Switch>
  );
};

export default BooksPageRoutes;

function PrivateRoute(params) {
  return (
    <Route
      render={props =>
        authenticate(window.sessionStorage.getItem('_eb_language'), params.path) !== -1 ? (
          <BooksComponent {...props} gamePath={params.gamePath} />
        ) : (
          <Redirect to={`${RoutePath.READ}/${RouteHelpers.BROWSE}`} />
        )
      }
    />
  );
}

function authenticate(language, path) {
  return getLocalesByBucketIdAndRouteId(1, path.split('/').pop()).indexOf(language);
}
