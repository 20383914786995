import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HomePage from './HomePage';

const HomePageContainer = props => {
  const { pageTitleHome } = props;

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitleHome}</title>
      </Helmet>
      <HomePage />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  pageTitleHome: state.languageWidget.translation['shell-page-title-home'],
});

HomePageContainer.propTypes = {
  pageTitleHome: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(HomePageContainer);
