// We can't use constants in this file as it is invoked fron
// a call within the constants file.

import br from '../i18n/localizations/pt-BR';
import en from '../i18n/localizations/en-US';

function getQueryParameter(key) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');

  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');

    if (pair[0] === key) {
      return pair[1];
    }
  }

  return null;
}

export function getLanguageData() {
  const uri = window.location.host;

  // this was breaking shit
  if (process.env.REACT_APP_ENV === 'dev') {
    // Check to see if there are locale query parameters
    const locale = getQueryParameter('locale');

    if (locale) {
      if (locale === 'en-US') {
        return en;
      }

      if (locale === 'pt-BR') {
        return br;
      }

      if (locale === 'en-AU') {
        return en;
      }

      if (locale === 'en-GB') {
        return en;
      }
    } else {
      if (uri.indexOf('.br') !== -1) {
        return br;
      }

      if (uri.indexOf('.co.uk') !== -1) {
        return en;
      }

      if (uri.indexOf('.com.au') !== -1) {
        return en;
      }

      return en;
    }
  } else {
    if (uri.indexOf('.br') !== -1) {
      return br;
    }

    if (uri.indexOf('.co.uk') !== -1) {
      return en;
    }

    if (uri.indexOf('.com.au') !== -1) {
      return en;
    }

    return en;
  }

  return en;
}

export function getLocationCode() {
  const uri = window.location.host;

  if (process.env.REACT_APP_ENV === 'dev') {
    // Check to see if there are locale query parameters
    const locale = getQueryParameter('locale');

    if (locale) {
      switch (locale) {
        case 'en-US':
          return 'en-US';
        case 'pt-BR':
          return 'pt-BR';
        case 'en-GB':
          return 'en-GB';
        case 'en-AU':
          return 'en-AU';
        default:
          return 'en-US';
      }
    } else {
      if (uri.indexOf('.br') !== -1) {
        return 'pt-BR';
      }

      if (uri.indexOf('.co.uk') !== -1) {
        return 'en-GB';
      }

      if (uri.indexOf('.com.au') !== -1) {
        return 'en-AU';
      }

      return 'en-US';
    }
  } else {
    // If we are not in the development environment
    if (uri.indexOf('.br') !== -1) {
      return 'pt-BR';
    }

    if (uri.indexOf('.co.uk') !== -1) {
      return 'en-GB';
    }

    if (uri.indexOf('com.au') !== -1) {
      return 'en-AU';
    }

    return 'en-US';
  }
}
