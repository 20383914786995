import { Markets, Categories } from '../constants';
import PlayManifest from '../Pages/Games/GameManifest';
import ReadManifest from '../Pages/Books/ReadManifest';

function getLocalesByBucketIdAndRouteId(bucketId, routeId) {
  const data = bucketId === 0 ? PlayManifest : ReadManifest;
  const itemData = data.find(item => item.id === routeId);

  if (!itemData) {
    return null;
  }

  // eslint-disable-next-line prefer-destructuring
  const categoryIds = data.find(item => item.id === routeId).categoryIds;

  return getLocalesByCategories(categoryIds);
}

function getLocalesByCategories(categories) {
  const marketIds = [];
  const languages = [];

  for (let i = 0; i < categories.length; i += 1) {
    marketIds.push(Categories.find(category => category.categoriesId === categories[i]).marketsId);
  }

  for (let i = 0; i < marketIds.length; i += 1) {
    languages.push(Markets.find(market => market.marketsId === marketIds[i]).marketsLanguage);
  }

  return languages;
}

function getCategoryNameByActiveCategory(categoryId) {
  return Categories.find(category => category.categoriesId === categoryId).categoriesName;
}

function getCategoriesByMarketLanguageAndBucketId(language, bucketId) {
  // Find the marketsId by language
  const marketsId = getMarketsIdFromMarketLanguage(language);

  // Find the categories based on the marketId
  const categories = Categories.filter(category => category.marketsId === marketsId);

  // Makes sure only categroies that belong to a certain bucket are returned
  return categories.filter(category => category.bucketId === bucketId);
}

function getMarketsIdFromMarketLanguage(marketsLanguage) {
  return Markets.find(market => market.marketsLanguage === marketsLanguage).marketsId;
}

function getCategoriesIdByCategories(categories) {
  const ret = [];

  categories.forEach(category => {
    ret.push(category.categoriesId);
  });

  return ret;
}
/**
 * @param  {int} categoryId
 * @param  {int} bucketsId
 * @returns  {set} {constassemblyList=newSet([]
 */

function getAssembliesByCategoryIdAndBucketsId(categoryId, bucketsId) {
  const assemblyList = new Set([]);
  const manifest = bucketsId === 0 ? PlayManifest : ReadManifest;

  for (let x = 0; x < manifest.length; x += 1) {
    for (let i = 0; i < manifest[x].categoryIds.length; i += 1) {
      if (manifest[x].categoryIds[i] === categoryId) {
        assemblyList.add(manifest[x]);
      }
    }
  }

  return assemblyList;
}

// function getAssembliesByCategoryIdsAndBucketsId(categoryIdArray, bucketsId) {
//   const assemblyList = new Set([]);
//   const manifest = bucketsId === 0 ? PlayManifest : ReadManifest;

//   for (let x = 0; x < manifest.length; x += 1) {
//     for (let i = 0; i < categoryIdArray.length; i += 1) {
//       if (manifest[x].categoryIds.indexOf(categoryIdArray[i])) {
//         assemblyList.add(manifest[x]);
//       }
//     }
//   }

//   return assemblyList;
// }

function getRandomNumber() {
  return new Date().getTime();
}
/**
 * @param  {String} contentId   [ID for game or book (e.g. "0001")]
 * @param  {String} contentType [Type of content (e.g. "game")]
 * @return {String}             [Title for game or book (e.g. "Measurement")]
 */

function getContentTitleFromContentId(contentId, contentType) {
  if (contentType === 'game') {
    return PlayManifest.filter(gameData => {
      return gameData.id === contentId;
    })[0].title;
  }

  return ReadManifest.filter(bookData => {
    return bookData.id === contentId;
  })[0].title;
}

export {
  getCategoryNameByActiveCategory,
  getCategoriesIdByCategories,
  getCategoriesByMarketLanguageAndBucketId,
  getMarketsIdFromMarketLanguage,
  getRandomNumber,
  getAssembliesByCategoryIdAndBucketsId,
  getLocalesByBucketIdAndRouteId,
  getLocalesByCategories,
  getContentTitleFromContentId,
};
