import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import './BooksPage.scss';
import {
  getCategoriesByMarketLanguageAndBucketId,
  getCategoriesIdByCategories,
  getAssembliesByCategoryIdAndBucketsId,
} from '../../Utils/browseUtilities';
import BrowseNavComponent from '../../Components/BrowseNavComponent/BrowseNavComponent';
import BrowseListComponent from '../../Components/BrowseListComponent/BrowseListComponent';
import LiveReader from '../../Components/LiveReader/LiveReader';
import { updateLiveReaderInfo } from '../../Utils/audioUtilities';

class BooksPageBrowseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: null,
      books: null,
      activeCategory: null,
    };
    this.introduction = null;
    this.handleBrowseNavTabClick = this.handleBrowseNavTabClick.bind(this);
  }

  componentDidMount() {
    const { startLanguage, booksBrowseScreenReaderGreeting } = this.props;
    const categories = getCategoriesByMarketLanguageAndBucketId(startLanguage, 1);
    const categoryIds = getCategoriesIdByCategories(categories);
    const books = getAssembliesByCategoryIdAndBucketsId(categoryIds[0], 1);

    this.setState({ categories, activeCategory: categoryIds[0], books });
    this.introduction = setTimeout(() => {
      clearTimeout(this.introduction);
      updateLiveReaderInfo(booksBrowseScreenReaderGreeting, 'interactive-text');
    }, 450);
  }

  handleBrowseNavTabClick(e) {
    const categoryId = e.currentTarget.getAttribute('data-category');

    document.querySelector(`button[data-category="${categoryId}"]`).focus();

    this.setState({
      activeCategory: +categoryId,
      books: getAssembliesByCategoryIdAndBucketsId(+categoryId[0], 1),
    });
  }

  render() {
    const { categories, activeCategory, books } = this.state;
    const { pageTitleBooks } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>{pageTitleBooks}</title>
        </Helmet>
        <section className="books-page-browse">
          {categories && (
            <BrowseNavComponent
              categories={categories}
              activeCategory={activeCategory}
              handleBrowseNavTabClick={this.handleBrowseNavTabClick}
            />
          )}

          {books && <BrowseListComponent assemblies={books} bucketId="1" activeCategory={activeCategory} />}
          <LiveReader classHolder="book-sumary" />
        </section>
      </Fragment>
    );
  }
}

BooksPageBrowseContainer.propTypes = {
  startLanguage: PropTypes.string.isRequired,
  pageTitleBooks: PropTypes.string.isRequired,
  booksBrowseScreenReaderGreeting: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  startLanguage: state.languageWidget.startLanguage,
  pageTitleBooks: state.languageWidget.translation['shell-page-title-read'],
  booksBrowseScreenReaderGreeting: state.languageWidget.translation['read-browse-screen-reader-greeting'],
});

export default connect(mapStateToProps)(BooksPageBrowseContainer);
