// Singular game

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Script from 'react-load-script';
import { getRandomNumber, getContentTitleFromContentId } from '../../../Utils/browseUtilities';
import '../Games.scss';
import LiveReader from '../../../Components/LiveReader/LiveReader';
import { updateLiveReaderInfo } from '../../../Utils/audioUtilities';

class GameComponent extends Component {
  ref = createRef();

  constructor(props) {
    super(props);
    this.eve = new Event('destroy', () => {
      console.log('Clean-up');
    });
    this.introduction = null;
  }

  componentDidMount() {
    const { gamePlayScreenReaderGreeting } = this.props;

    this.introduction = setTimeout(() => {
      clearTimeout(this.introduction);
      updateLiveReaderInfo(gamePlayScreenReaderGreeting, 'interactive-text');
    }, 450);
  }

  componentWillUnmount() {
    dispatchEvent(this.eve);
  }

  render() {
    const { gamePath, location, startLanguage } = this.props;
    const gameId = location.pathname.split('/').pop();
    const rand = getRandomNumber();

    return (
      <div style={{ height: '100%' }}>
        <Helmet>
          <title>{getContentTitleFromContentId(gameId, 'game')}</title>
        </Helmet>
        <LiveReader classHolder="game-page" />
        <div id="game" />
        <Script url={`/vendor/fablevision/master/build/${gamePath}/main-${startLanguage}.js?t=${rand}`} />
      </div>
    );
  }
}

GameComponent.propTypes = {
  gamePath: PropTypes.string.isRequired,
  location: PropTypes.shape().isRequired,
  gamePlayScreenReaderGreeting: PropTypes.string.isRequired,
  startLanguage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  startLanguage: state.languageWidget.startLanguage,
  pageTitleGames: state.languageWidget.translation['shell-page-title-play'],
  gamePlayScreenReaderGreeting: state.languageWidget.translation['play-game-screen-reader-greeting'],
});

export default connect(mapStateToProps)(GameComponent);
