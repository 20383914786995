import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './SkipToMainContentLink.scss';

const SkipToMainContentLink = props => {
  const { skipToMainContentLabel } = props;

  return (
    <a
      href="#main-content"
      className="sr-only skip-to-main-content-link"
      tabIndex="0"
      aria-label={skipToMainContentLabel}
    >
      {skipToMainContentLabel}
    </a>
  );
};

SkipToMainContentLink.propTypes = {
  skipToMainContentLabel: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  skipToMainContentLabel: state.languageWidget.translation['shell-label-skip-to-main-content'],
});

export default connect(mapStateToProps)(SkipToMainContentLink);
