/* eslint-disable no-console */
import store from '../Redux/store';

class AnalyticsApi {
  static writeStat() {
    try {
      const currentStateOfStatsManager = store.getState().statsManager;
      const { accountId, hostnameOverride, overrideDomain } = currentStateOfStatsManager;

      if (accountId && hostnameOverride && overrideDomain) {
        window.writeStat('accountId', {
          domain: overrideDomain,
          hostnameOverride,
          accountId,
        });
      } else {
        throw new Error('Webstats configuration not set');
      }
    } catch (err) {
      console.error(err);
    }
  }

  static manualWriteStat(requestUrl) {
    try {
      const currentStateOfStatsManager = store.getState().statsManager;
      const { accountId, hostnameOverride, overrideDomain } = currentStateOfStatsManager;

      if (accountId && hostnameOverride && overrideDomain && requestUrl) {
        window.writeStat('accountId', {
          domain: overrideDomain,
          hostnameOverride,
          accountId,
          requestUrl,
        });
      } else {
        throw new Error('Webstats configuration not set');
      }
    } catch (err) {
      console.error(err);
    }
  }
}

export default AnalyticsApi;
