// Singular book page

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Script from 'react-load-script';
import { getRandomNumber, getContentTitleFromContentId } from '../../../Utils/browseUtilities';
import LiveReader from '../../../Components/LiveReader/LiveReader';
import { updateLiveReaderInfo } from '../../../Utils/audioUtilities';
import { Endpoint } from '../../../constants';

class BooksComponent extends Component {
  ref = createRef();

  constructor(props) {
    super(props);
    this.eve = new Event('read-destroy', () => {
      console.log('Clean-up');
    });
    this.introduction = null;
  }

  componentDidMount() {
    const { bookReadScreenReaderGreeting } = this.props;

    this.introduction = setTimeout(() => {
      clearTimeout(this.introduction);
      updateLiveReaderInfo(bookReadScreenReaderGreeting, 'interactive-text');
    }, 450);
  }

  componentWillUnmount() {
    window.dispatchEvent(this.eve);
  }

  render() {
    const { gamePath, location, bookNameString } = this.props;
    const gameId = location.pathname.split('/').pop();
    const rand = getRandomNumber();

    return (
      <>
        <Helmet>
          <title>{getContentTitleFromContentId(gameId, 'book')}</title>
        </Helmet>
        <div id="book-string" data-book-string={bookNameString} data-mitr-cdn={Endpoint.CDN_MITR_URL} />
        <Script url={`/vendor/mitr/${gamePath}/player/single-file-entry.js?t=${rand}`} />
        <LiveReader classHolder="book-page" />
      </>
    );
  }
}

BooksComponent.propTypes = {
  gamePath: PropTypes.string.isRequired,
  location: PropTypes.shape().isRequired,
  bookReadScreenReaderGreeting: PropTypes.string.isRequired,
  bookNameString: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  startLanguage: state.languageWidget.startLanguage,
  pageTitleGames: state.languageWidget.translation['shell-page-title-play'],
  bookReadScreenReaderGreeting: state.languageWidget.translation['read-book-screen-reader-greeting'],
  bookNameString: state.languageWidget.translation[`${window.location.pathname.split('/').pop()}-read-path`],
});

export default connect(mapStateToProps)(BooksComponent);
