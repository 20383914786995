import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BrowseListTileComponent from '../BrowseListTileComponent/BrowseListTileComponent';
import './BrowseListComponent.scss';
import { getCategoryNameByActiveCategory } from '../../Utils/browseUtilities';

const BrowseListComponent = props => {
  const { assemblies, bucketId, activeCategory } = props;

  const browseTiles = Array.from(assemblies);

  // Sort the tiles in alphabetical order by titlle
  browseTiles.sort((a, b) => {
    const nameA = a.title;
    const nameB = b.title;

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const categoryName = getCategoryNameByActiveCategory(activeCategory);
  const bucketClass = bucketId === '0' ? 'play' : 'read';
  const sectionClass = cx('browse-content', 'browse-list-component', bucketClass);

  return (
    <section role="tabpanel" aria-live="assertive" id="browse-list" className={cx('browse-list-component--wrapper', bucketClass)}>
      <h1 className={cx('browse-list-component--category-header-title')}>{categoryName}</h1>
      <div className="browse-list-component">
        <section className={sectionClass}>
          {browseTiles &&
            browseTiles.map(tile => (
              <BrowseListTileComponent
                classname={bucketClass}
                key={tile.id}
                id={tile.id}
                title={tile.title}
                route={tile.route}
                topic={tile.topic}
                imagePath={tile.imagePath}
                imagePathAltText={tile.imagePathAltText}
              />
            ))}
        </section>
      </div>
    </section>
  );
};

BrowseListComponent.propTypes = {
  assemblies: PropTypes.shape(),
  bucketId: PropTypes.string,
  activeCategory: PropTypes.number,
};

BrowseListComponent.defaultProps = {
  assemblies: null,
  bucketId: '0',
  activeCategory: 0,
};

export default BrowseListComponent;
