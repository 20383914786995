import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './PrimaryNavigation.scss';

const PrimaryNavigationItem = props => {
  const { linkHref, imgSrc, imgAltText, label, btnLabel } = props;

  return (
    <NavLink
      className="primary-navigation--nav-item"
      to={linkHref}
      id={label}
      onClick={() => {
        document.getElementById(label).focus();
      }}
    >
      <img src={imgSrc} alt={imgAltText} aria-hidden="true" />
      <div className="primary-navigation--nav-item--label" aria-label={btnLabel}>
        {label}
      </div>
    </NavLink>
  );
};

export default PrimaryNavigationItem;

PrimaryNavigationItem.propTypes = {
  linkHref: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAltText: PropTypes.string,
  label: PropTypes.string,
  btnLabel: PropTypes.string,
};

PrimaryNavigationItem.defaultProps = {
  linkHref: null,
  imgSrc: null,
  imgAltText: null,
  label: null,
  btnLabel: null,
};
