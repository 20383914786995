import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import HeaderOnlyTemplate from '../Templates/HeaderOnly/HeaderOnlyTemplate';
import LostOtterComponent from '../../Components/LostOtterComponent/LostOtterComponent';
import './GenericNotFound.scss';

const GenericNotFound = props => {
  const { otterAlt, pageTitleNotFound } = props;

  return (
    <HeaderOnlyTemplate>
      <Helmet>
        <title>{pageTitleNotFound}</title>
      </Helmet>
      <div className="generic-not-found">
        <h1>
          <Translate id="shell-404-text-header" />
        </h1>
        <h2>
          <Translate id="shell-404-text-subheader" />
        </h2>
        <LostOtterComponent altTag={otterAlt} />
        {/* <img className="center" alt={otterAlt} src={`${Endpoint.CDN_URL}/learning-zone/images/Shell/otter-404.png`} /> */}
        <p>
          <Translate id="shell-404-text-message-part-1" />
          <Link className="link-button" to="/">
            <Translate id="shell-404-text-go-home-button" />
          </Link>
          <Translate id="shell-404-text-message-part-2" />
        </p>
      </div>
    </HeaderOnlyTemplate>
  );
};

const mapStateToProps = state => ({
  otterAlt: state.languageWidget.translation['shell-404-alt-text-lost-otter'],
  pageTitleNotFound: state.languageWidget.translation['shell-page-title-not-found'],
});

GenericNotFound.propTypes = {
  otterAlt: PropTypes.string.isRequired,
  pageTitleNotFound: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(GenericNotFound);
