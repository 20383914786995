import React from 'react';
import BooksPageRoute from './BooksPage.routes';
import BrowseTemplate from '../Templates/Browse/BrowseTemplate';
import './BooksPage.scss';

const BooksPageContainer = () => {
  return (
    <BrowseTemplate>
      <BooksPageRoute />
    </BrowseTemplate>
  );
};

export default BooksPageContainer;
