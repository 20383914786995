import { SET_ACCOUNT_ID, SET_HOSTNAME_OVERRIDE, SET_OVERRIDE_DOMAIN } from '../actionTypes';

const initialState = { accountId: null, hostnameOverride: null, overrideDomain: null };

const statsManager = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_ID: {
      const { accountId } = action;

      return { ...state, accountId };
    }

    case SET_HOSTNAME_OVERRIDE: {
      const { hostnameOverride } = action;

      return { ...state, hostnameOverride };
    }

    case SET_OVERRIDE_DOMAIN: {
      const { overrideDomain } = action;

      return { ...state, overrideDomain };
    }

    default: {
      return state;
    }
  }
};

export default statsManager;
