import React from 'react';
import PropTypes from 'prop-types';
import './LiveReader.scss';

const LiveReader = props => {
  const { classHolder } = props;

  return (
    <div role="region" className={`${classHolder} live-reader`}>
      <p aria-live="assertive" id="interactive-text" />
    </div>
  );
};

LiveReader.propTypes = {
  classHolder: PropTypes.string.isRequired,
};

export default LiveReader;
