import React from 'react';
import PropTypes from 'prop-types';
import BrowseNavTabComponent from '../BrowseNavTabComponent/BrowseNavTabComponent';

import './BrowseNavComponent.scss';

const BrowseNavComponent = props => {
  const { categories, activeCategory, handleBrowseNavTabClick } = props;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
    <nav role="tablist" orientation="horizontal" className="browse-navigation">
      {categories &&
        categories.map(category => (
          <BrowseNavTabComponent
            key={category.categoriesId}
            isActive={activeCategory === category.categoriesId}
            dataCategory={category.categoriesId}
            handleClick={handleBrowseNavTabClick}
            categoriesName={category.categoriesName}
            icon={category.icon}
          />
        ))}
    </nav>
  );
};

BrowseNavComponent.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape()),
  activeCategory: PropTypes.number,
  handleBrowseNavTabClick: PropTypes.func.isRequired,
};

BrowseNavComponent.defaultProps = {
  categories: null,
  activeCategory: 0,
};

export default BrowseNavComponent;
