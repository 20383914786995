import { HostnameOverrides, OverrideDomains, Environments, Locales } from '../constants';

const ProductionOverrideDomains = OverrideDomains.PROD;
const QAOverrideDomains = OverrideDomains.QA;
const DevOverrideDomains = OverrideDomains.DEV;

/**
 * @param   {[String]} locationCode [Location code for market]
 * @param   {[String]} environment  [Environment: prod, dev, qa]
 * @returns {[String]}              [Override domain for stats]
 */

export function getOverrideDomain(locationCode, environment) {
  if (locationCode === Locales.ENGLISH_US) {
    switch (environment) {
      case Environments.PRODUCTION:
        return ProductionOverrideDomains.US;
      case Environments.QA:
        return QAOverrideDomains.US;
      case Environments.DEV:
        return DevOverrideDomains.US;
      default:
        return null;
    }
  }

  if (locationCode === Locales.ENGLISH_AUSTRALIAN) {
    switch (environment) {
      case Environments.PRODUCTION:
        return ProductionOverrideDomains.AU;
      case Environments.QA:
        return QAOverrideDomains.AU;
      case Environments.DEV:
        return DevOverrideDomains.AU;
      default:
        return null;
    }
  }

  if (locationCode === Locales.ENGLISH_BRITISH) {
    switch (environment) {
      case Environments.PRODUCTION:
        return ProductionOverrideDomains.GB;
      case Environments.QA:
        return QAOverrideDomains.GB;
      case Environments.DEV:
        return DevOverrideDomains.GB;
      default:
        return null;
    }
  }

  return null;
}

/**
 * @param   {[String]} pathname [Current pathname]
 * @returns {[String]}          [Environment: prod, dev, qa]
 */

export function getEnvironmentFromPathname(pathname) {
  if (pathname.includes('school.eb.com')) {
    return Environments.PRODUCTION;
  }

  if (pathname.includes('.dev.') || pathname.includes('k2.local.eb.com')) {
    return Environments.DEV;
  }

  if (pathname.includes('.qa.')) {
    return Environments.QA;
  }

  return Environments.DEV;
}

/**
 * @param   {[String]} environment [Environment: prod, dev, qa]
 * @returns {[String]}             [Hostname override for stats]
 */

export function getHostnameOverride(environment) {
  switch (environment) {
    case Environments.PRODUCTION:
      return HostnameOverrides.PROD;
    case Environments.QA:
      return HostnameOverrides.QA;
    case Environments.DEV:
      return HostnameOverrides.DEV;
    default:
      return null;
  }
}

export default { getHostnameOverride, getEnvironmentFromPathname, getOverrideDomain };
