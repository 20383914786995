import { createStore } from 'redux';
import rootReducer from './reducers/index';

const initialState = {};

// const enhancers = [];

// if (process.env.NODE_ENV === 'development') {

export default createStore(
  rootReducer,
  initialState,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
