import FetchManager from './FetchManager';
import { Endpoint } from '../constants';

class ContentApi extends FetchManager {
  fetchAssemblyByAssemblyId(assemblyId) {
    return this.fetch(`${Endpoint.BACKEND_API}/assembly/${assemblyId}`).catch(error => error);
  }
}

/*
  TODO: Need to use instance of the ContentApi instead of the class itself (A.B.)

  Export instance of ContentApi.
  For now we are exporting both the Class ContentApi (default) and an instance of it.
  Currently the instance is only being used in SearchResultsPageContainer & HomePageContainer.
  We are close to launching Escola and don't want to make a global change at the moment (Feb/4/19 A.B.).
  We will eventually use the instance of the ContentApi instead of the class itself to make use of the FetchManager that it extends off of.
*/
export default new ContentApi();
