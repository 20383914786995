import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { togglePause } from '../../../Redux/actions/pauseManagerActions';
import Header from '../../../Components/Header/Header';
import ScreenSize from '../../../Components/ScreenSize/ScreenSize';
import PrimaryNavigation from '../../../Components/PrimaryNavigation/PrimaryNavigation';
import './BrowseTemplate.scss';

class BrowseTemplate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mediaList: window.matchMedia('(min-width: 48em)'),
      tooSmall: window.innerWidth < 48,
    };

    const { mediaList } = this.state;

    this.screenSizeTest = this.screenSizeTest.bind(this);
    mediaList.addListener(this.screenSizeTest);
  }

  componentWillUnmount() {
    const { mediaList } = this.state;

    mediaList.removeListener(this.screenSizeTest);
  }

  screenSizeTest(e) {
    // eslint-disable-next-line no-shadow
    const { togglePause } = this.props;

    if (!e.matches) {
      this.setState({ tooSmall: true }, () => {
        togglePause();
      });
    } else {
      this.setState({ tooSmall: false }, () => {
        togglePause();
      });
    }
  }

  render() {
    const { children, startLanguage } = this.props;
    const { tooSmall } = this.state;

    return (
      <Fragment>
        <Helmet htmlAttributes={{ lang: startLanguage, class: startLanguage }} />
        <Header startLanguage={startLanguage} />
        <PrimaryNavigation />
        <div className="wrapper browse-template">
          <div className="sixteen-nine">
            <main className="content" id="main-content">
              {children}
            </main>
          </div>
        </div>
        <ScreenSize isOpen={tooSmall} />
      </Fragment>
    );
  }
}

BrowseTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  startLanguage: PropTypes.string.isRequired,
  togglePause: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  startLanguage: state.languageWidget.startLanguage,
});

export default connect(
  mapStateToProps,
  { togglePause }
)(BrowseTemplate);
