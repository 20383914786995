import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FooterConstants } from './FooterConstants';
import { Endpoint, Env } from '../../constants';
import { determineNumberOfLinks } from './FooterUtil';
import './Footer.scss';

class Footer extends Component {
  constructor(props) {
    console.log(Env);
    super(props);

    this.state = {
      screenWidthMatch: false,
      numOfLinks: determineNumberOfLinks(FooterConstants.LINKS),
    };

    this.checkScreenWidth = this.checkScreenWidth.bind(this);
  }

  componentDidMount() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScreenWidth);
  }

  checkScreenWidth() {
    const screenWidthMatch = window.matchMedia('(max-width: 1025px)');

    if (screenWidthMatch) {
      this.setState({
        screenWidthMatch: screenWidthMatch.matches,
      });
    }
  }

  render() {
    const { brandingFooterAlt, brandingFooterPath } = this.props;
    const { screenWidthMatch, numOfLinks } = this.state;

    return (
      <footer className="Footer">
        <div className="footer-content">
          <div className="footer-branding">
            <img src={`${Endpoint.CDN_URL}${brandingFooterPath}`} alt={brandingFooterAlt} aria-hidden="true" />
            <span className="sr-only">
              <Translate id="shell-sr-branding-footer" />
            </span>
          </div>
          <div className="link-container">
            <div className="links">
              <div>
                {(screenWidthMatch || !screenWidthMatch) &&
                  FooterConstants.LINKS.map((link, index) => {
                    if (link.href !== 'null') {
                      if (!screenWidthMatch) {
                        return (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={link.href}
                            className="footer-link"
                            key={link.name}
                          >
                            {link.name}
                          </a>
                        );
                      }

                      if (screenWidthMatch) {
                        if ((index <= 2 && numOfLinks > 4) || numOfLinks <= 4) {
                          return (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={link.href}
                              className="footer-link"
                              key={link.name}
                            >
                              {link.name}
                            </a>
                          );
                        }
                      }

                      return null;
                    }

                    return null;
                  })}
              </div>
              <div>
                {screenWidthMatch &&
                  FooterConstants.LINKS.map((link, index) => {
                    if (index > 2 && link.href !== 'null' && numOfLinks > 4) {
                      return (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={link.href}
                          className="footer-link"
                          key={link.name}
                        >
                          {link.name}
                        </a>
                      );
                    }

                    return null;
                  })}
              </div>
            </div>
            <div className="copyright">
              <Translate id="footer-copyright-label" /> | v{Env.VERSION} - {Env.RELEASE}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  brandingFooterAlt: PropTypes.string.isRequired,
  brandingFooterPath: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  brandingFooterAlt: state.languageWidget.translation['shell-alt-branding-footer'],
  brandingFooterPath: state.languageWidget.translation['shell-name-image-footer-branding'],
});

export default connect(mapStateToProps)(Footer);
