import { BiomeTypes, AssemblyDefinitions, SpotlightDefinitions } from './MercatorConstants';

/**
 * @param  {string} biome
 */
function calculateBiomeState(biome) {
  switch (biome) {
    case BiomeTypes.TUNDRA:
      return {
        tundra: true,
        desert: false,
        taiga: false,
        temperateForest: false,
        tropicalForest: false,
        grassland: false,
      };
    case BiomeTypes.DESERT:
      return {
        tundra: false,
        desert: true,
        taiga: false,
        temperateForest: false,
        tropicalForest: false,
        grassland: false,
      };
    case BiomeTypes.TAIGA:
      return {
        tundra: false,
        desert: false,
        taiga: true,
        temperateForest: false,
        tropicalForest: false,
        grassland: false,
      };
    case BiomeTypes.TEMPERATE_FOREST:
      return {
        tundra: false,
        desert: false,
        taiga: false,
        temperateForest: true,
        tropicalForest: false,
        grassland: false,
      };
    case BiomeTypes.TROPICAL_FOREST:
      return {
        tundra: false,
        desert: false,
        taiga: false,
        temperateForest: false,
        tropicalForest: true,
        grassland: false,
      };
    case BiomeTypes.GRASSLAND:
      return {
        tundra: false,
        desert: false,
        taiga: false,
        temperateForest: false,
        tropicalForest: false,
        grassland: true,
      };
    default:
      return {
        tundra: true,
        desert: true,
        taiga: true,
        temperateForest: true,
        tropicalForest: true,
        grassland: true,
      };
  }
}

/**
 * @param  {string} name
 */
function removeToggle(name) {
  const index = name.indexOf('-toggle');

  if (index !== -1) {
    return name.slice(0, index);
  }

  return name;
}

/**
 * @param  {string} name
 */
function convertToCamelCase(name) {
  // eslint-disable-next-line no-useless-escape
  const find = /(\-\w)/g;

  return name.replace(find, matches => {
    return matches[1].toUpperCase();
  });
}

/**
 * @param  {string} id
 * @param  {string} locale='pt-BR'
 */
function convertIdToAssembly(id, locale = 'pt-BR') {
  const regex = /(_[0-9]{4})\b/g;

  return AssemblyDefinitions[regex.exec(id)[0]][locale];
}

function validateAssemblyId(id) {
  const regex = /(_[0-9]{4})\b/g;

  if (id.match(regex)) {
    return true;
  }

  return false;
}

/**
 * @param  {string} biomeName
 * @param  {string} locale='pt-BR'
 */
function getBiomeSotlightAssemblyIdByBiomeAndLocale(biomeName, locale = 'pt-BR') {
  return SpotlightDefinitions[biomeName][locale];
}

export {
  validateAssemblyId,
  calculateBiomeState,
  removeToggle,
  convertToCamelCase,
  convertIdToAssembly,
  getBiomeSotlightAssemblyIdByBiomeAndLocale,
};
