import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class RouteChange extends Component {
  constructor(props) {
    super(props);

    this.unlistenFromOnRouteChange = () => {};

    this.onRouteChange = this.onRouteChange.bind(this);
  }

  componentDidMount() {
    const { history, location, onRouteChange } = this.props;

    if (onRouteChange !== null) {
      // Trigger callback on initial page load
      this.onRouteChange(location);

      // Trigger callback on subsequent route changes
      this.unlistenFromOnRouteChange = history.listen(this.onRouteChange);
    }
  }

  componentWillUnmount() {
    this.unlistenFromOnRouteChange();
  }

  onRouteChange(location) {
    const { onRouteChange } = this.props;

    onRouteChange.forEach(callback => {
      switch (callback.name) {
        case 'backgroundChange':
          callback.func(location);
          break;
        case 'writeStat':
          callback.func();
          break;
        default:
          break;
      }
    });
  }

  render() {
    const { children } = this.props;

    return <Fragment>{children}</Fragment>;
  }
}

export default withRouter(RouteChange);

RouteChange.propTypes = {
  onRouteChange: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.string]),
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }).isRequired,
};

RouteChange.defaultProps = {
  children: '',
  onRouteChange: null,
};
