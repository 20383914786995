import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import audioWidget from './audioWidget';
import languageWidget from './languageWidget';
import animationManager from './animationManager';
import pauseManager from './pauseManager';
import statsManager from './statsManager';

export default combineReducers({
  audioWidget,
  languageWidget,
  animationManager,
  pauseManager,
  localize: localizeReducer,
  statsManager,
});
