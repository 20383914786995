import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './App.scss';
import { withLocalize } from 'react-localize-redux';
import { Router } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import RouteChange from './Components/RouteChange/RouteChange';
import { backGroundChange } from './Utils/uriUtilities';
import history from './history';
import br from './i18n/localizations/pt-BR.json';
import en from './i18n/localizations/en-US.json';
import AppRoutes from './App.routes';
import { getLocationCode } from './Utils/localeUtilities';
import AnalyticsApi from './Services/AnalyticsApi';
import { getCookieByName } from './Utils/appUtilities';
import { WebStats } from './constants';
import { setAccountId, setHostnameOverride, setOverrideDomain } from './Redux/actions/statsActions';
import { getEnvironmentFromPathname, getHostnameOverride, getOverrideDomain } from './Utils/statsUtilities';

const brazil = {
  languages: [{ name: 'Portuguese', code: 'pt-BR' }],
  translation: br,
  options: { renderToStaticMarkup },
};

const english = {
  languages: [{ name: 'English', code: 'en-US' }],
  translation: en,
  options: { renderToStaticMarkup },
};

class App extends Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line no-shadow
    const { initialize, setAccountId, setHostnameOverride, setOverrideDomain, startLanguage } = this.props;
    const { href } = window.location;
    const code = getLocationCode();

    switch (code) {
      case 'en-US':
        initialize(english);
        break;
      case 'pt-BR':
        initialize(brazil);
        break;
      case 'en-AU':
        initialize(english);
        break;
      case 'en-GB':
        initialize(english);
        break;
      default:
        initialize(english);
        break;
    }

    document.addEventListener('audio-change', () => {
      console.log('my awesome callback');
    });

    const accountId = getCookieByName(document.cookie, 'accountid');
    const k2RegEx = /(k2)((.dev)?|(.qa)?)(.eb.com)/;
    const k2LocalHrefContains = 'k2.local.eb.com';
    const schoolProdHrefContains = 'school.eb.com';

    try {
      if (
        accountId &&
        (k2RegEx.test(href) || href.includes(k2LocalHrefContains) || href.includes(schoolProdHrefContains))
      ) {
        // we need to set the account ID, hostname override and override domain to send to the stats server
        setAccountId(accountId);

        // determine environment
        const environment = getEnvironmentFromPathname(href);

        // determine hostname override
        const hostnameOverride = getHostnameOverride(environment);

        setHostnameOverride(hostnameOverride);

        // determine override domain
        const overrideDomain = getOverrideDomain(startLanguage, environment);

        setOverrideDomain(overrideDomain);
      } else {
        // throw new Error('Unable to set WebStats configuration.');
        // Temporary logic until authentication wrapper (k2-webapp) is being used
        // TODO: Need to add in logic for pt-BR - should we update .env and netlify.toml environment
        // variables with Escola writeStat parameters?

        const { ACCOUNT_ID, HOST, HOSTNAME_OVERRIDE } = WebStats;

        setAccountId(ACCOUNT_ID);
        setHostnameOverride(HOSTNAME_OVERRIDE);
        setOverrideDomain(HOST);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  render() {
    const { pageDefaultTitle, pageTitleTemplate } = this.props;

    return (
      <Router history={history}>
        <RouteChange
          onRouteChange={[
            { name: 'backgroundChange', func: backGroundChange },
            { name: 'writeStat', func: AnalyticsApi.writeStat },
          ]}
        >
          <Helmet defaultTitle={pageDefaultTitle} titleTemplate={pageTitleTemplate} />
          <AppRoutes />
        </RouteChange>
      </Router>
    );
  }
}

App.propTypes = {
  initialize: PropTypes.func.isRequired,
  pageDefaultTitle: PropTypes.string.isRequired,
  pageTitleTemplate: PropTypes.string.isRequired,
  setAccountId: PropTypes.func.isRequired,
  setHostnameOverride: PropTypes.func.isRequired,
  setOverrideDomain: PropTypes.func.isRequired,
  startLanguage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  pageTitleTemplate: state.languageWidget.translation['shell-page-title-template'],
  pageDefaultTitle: state.languageWidget.translation['shell-page-default-title'],
  startLanguage: state.languageWidget.startLanguage,
});

export default withLocalize(
  connect(
    mapStateToProps,
    { setAccountId, setOverrideDomain, setHostnameOverride }
  )(App)
);
